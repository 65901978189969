document.addEventListener("turbolinks:load", () => {
  let cardElement = document.querySelector("#card-element")
  if (cardElement !== null) { setupStripe() }
})

function setupStripe() {
  const stripe = Stripe(document.querySelector("meta[name='stripe-key']").getAttribute("content"))

  const elements = stripe.elements()
  const card = elements.create('card')
  card.mount('#card-element')

	var displayError = document.getElementById('card-errors')
  card.addEventListener("change", (event) => {
    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

	const form = document.querySelector("#payment-form")
  let paymentIntentClientSecret = form.dataset.paymentIntentClientSecret
  let setupIntentClientSecret   = form.dataset.setupIntentClientSecret
	
  if (paymentIntentClientSecret) {
    if (form.dataset.status == "requires_action") {
      stripe.confirmCardPayment(paymentIntentClientSecret, { setup_future_usage: 'off_session' }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          form.querySelector("#card-details").classList.remove("d-none")
        } else {
          form.submit()
        }
      })
    }
  }
	
  form.addEventListener("submit", (event) => {
    event.preventDefault()
    let data = { payment_method: { card: card, billing_details: { name: form.querySelector("#name_on_card").value }}}
	
		if (paymentIntentClientSecret) {
			// data.setup_future_usage = 'off_session'
	    stripe.confirmCardPayment(paymentIntentClientSecret, data).then((result) => {
	      if (result.error) {
	        displayError.textContent = result.error.message
	      } else {
	        form.submit()
	      }
	    })
		} else if (setupIntentClientSecret) {		
      stripe.confirmCardSetup(setupIntentClientSecret, data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
        } else {
          addHiddenField(form, "payment_method_id", result.setupIntent.payment_method)
          form.submit()
        }
      })
		}
  })
}

function addHiddenField(form, name, value) {
  let input = document.createElement("input")
  input.setAttribute("type", "hidden")
  input.setAttribute("name", name)
  input.setAttribute("value", value)
  form.appendChild(input)
}