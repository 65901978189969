window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
		alert("Attachment types supported are jpeg, png, and pdf")
  }

  const maxFileSize = 2*1024*1024 // 1MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
		alert("Attachment size must be less than 2 MB")
  }
})